<template class="tw-px-[var(--padding-left)] tw-py-[var(--padding-top)]">
      <div class="tw-flex tw-gap-4 tw-rounded-2xl tw-bg-pink-600 tw-p-2 max-2xl:tw-border-2 max-2xl:tw-border-pink-800 2xl:tw-gap-11 2xl:tw-p-5">
        <v-img
            :aspect-ratio="1"
            contain
            class="tw-w-full tw-max-w-[5.5rem] tw-rounded-xl max-lg:tw-mx-auto 2xl:tw-max-w-[8.75rem]"
            src="https://picsum.photos/600/600"></v-img>
        <div class="order-grid tw-grow 2xl:tw-py-6">
            <h1 class="grid-area-title tw-text-sm 2xl:tw-text-2xl">{{ $t('order.text') }} #{{ order.id }}</h1>
            <div class="grid-area-actions tw-flex tw-items-center tw-gap-3 tw-self-start sm:tw-gap-8">
                <v-btn
                    color="primary"
                    class="max-2xl:tw-text-xs"
                    :size="xlAndUp ? 'default' : 'x-small'"
                    >{{ $t('shared.seeDetails') }}</v-btn
                >
                <span class="max-2xl:tw-text-xs">+ 3 ECO Points</span>
            </div>
            <div class="grid-area-status tw-flex tw-flex-col max-2xl:tw-mb-3 2xl:tw-text-right">
                <span
                    class="tw-text-sm tw-font-bold 2xl:tw-text-2xl"
                    :class="status.color"
                    >{{ status.text }}</span
                >
                <span class="max-2xl:tw-text-xs 2xl:tw-ml-auto 2xl:tw-max-w-[12rem]">{{ $t('views.profile.cardOrder.supplierDidntAccept') }}</span>
            </div>
        </div>
    </div>
</template>



<script setup lang="ts">
    import { ref, onMounted } from 'vue';
    import axios from 'axios';
    import { useDisplay } from 'vuetify';
    import { useOrder } from '@/composables/useOrder';

    const { xlAndUp } = useDisplay();

    const props = defineProps({
        order: {
            type: Object,
            required: true,
        },
    });

    const uOrder = useOrder(props.order);

    const status = uOrder.getStatus();


    const orders = ref([]);


    const fetchOrders = async () => {
        try {
            const response = await axios.get('/orders');
            orders.value = response.data;
        } catch (error) {
            console.error(error);
        }
    };


    onMounted(fetchOrders);
</script>

<style scoped>
    .order-grid {
        display: grid;
        grid-template-areas:
            'title'
            'status'
            'actions';
    }

    @media (min-width: 1536px) {
        .order-grid {
            grid-template-areas:
                'title status'
                'actions status';
        }
    }

    .grid-area-title {
        grid-area: title;
    }

    .grid-area-status {
        grid-area: status;
    }

    .grid-area-actions {
        grid-area: actions;
    }
</style>
