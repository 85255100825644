<template class="tw-px-[var(--padding-left)] tw-py-[var(--padding-top)]">
    <div class="tw-flex tw-items-center sm:tw-hidden">
        <router-link to="/profile">
            <v-btn
                class="tw-text-2xl"
                icon="mdi-chevron-left"
                variant="tonal"
                color="green-900"></v-btn>
        </router-link>

       
    </div>
    <h1 class="tw-px-[var(--padding-left)] tw-text-lg tw-font-bold"> Requerimento de Espaços de Experimentação:</h1>

    <div class="tw-px-[var(--padding-left)] tw-pb-[var(--padding-left)] tw-grid tw-grid-cols-1 tw-gap-4 md:tw-grid-cols-2 2xl:tw-gap-16">
        <CardPedidosCentrosTecnologicos
            v-for="order in orders"
            :key="order.id"
            :order="order" />
    </div>
</template>

<script setup lang="ts">
    import CardPedidosCentrosTecnologicos from './CardPedidosCentrosTecnologicos.vue';

    // const orderStatuses = ['completed', 'pending', 'cancelled', 'processing', 'delivering'];
    const orderStatuses = ['completed', 'cancelled'];

    const orders: any[] = [];

    for (let i = 0; i < 10; i++) {
        orders.push({
            // id must be random
            id: Math.floor(Math.random() * 1000),
            status: orderStatuses[Math.floor(Math.random() * orderStatuses.length)],
            description: 'O vendedor não aceitou a sua solicitação',
        });
    }
</script>

<style scoped></style>
