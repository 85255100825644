<template class="tw-px-[var(--padding-left)] tw-py-[var(--padding-top)]">
    <div class="tw-flex tw-items-center tw-justify-between sm:tw-hidden">
        <router-link to="/profile">
            <v-btn
                class="tw-text-2xl"
                icon="mdi-chevron-left"
                variant="tonal"
                color="green-900"></v-btn>
        </router-link>

        <h1 class="tw-text-2xl">Formulários</h1>

        <v-btn
            class="tw-text-2xl"
            :icon="!isEditing ? 'mdi-square-edit-outline' : 'mdi-content-save'"
            variant="text"
            @click="onSubmit"
            color="green-900"></v-btn>
    </div>

    <div class="tw-mt-8 tw-flex tw-gap-8 lg:tw-gap-16">
        <!-- Coluna da Imagem -->
        <div class="tw-flex tw-w-1/4 tw-items-start tw-justify-center">
            <img
                src="/forms.png"
                alt="Imagem decorativa"
                class="tw-rounded-lg tw-object-cover" />
        </div>

        <!-- Coluna do Menu -->
        <div class="tw-w-1/4">
            <v-tabs
                v-model="selectedForm"
                direction="vertical"
                color="green-900">
                <v-tab value="Questionário Inicial">Questionário Inicial</v-tab>
                <v-tab value="Declaração de Validação">Declaração de Validação</v-tab>
                <v-tab value="Requerimento Espacos Experimentacao">Espaços Experimentação</v-tab>
            </v-tabs>
        </div>

        <!-- Coluna dos Formulários -->
        <div class="tw-w-1/2">
            <v-window v-model="selectedForm">
                <v-window-item value="Questionário Inicial">
                    <div class="tw-bg-gray-100 tw-rounded-lg tw-p-4">
                        <div
                            v-for="(item, index) in perguntasRespondidas"
                            :key="index"
                            class="tw-mb-4">
                            <p class="tw-text-base tw-font-semibold">{{ item.texto }}</p>
                            <p class="tw-text-green-900">Resposta: {{ item.resposta }}</p>
                        </div>

                        <div v-if="!resultadoFinal">
                            <p class="tw-mb-4 tw-text-base">{{ perguntaAtual.texto }}</p>
                            <v-radio-group
                                v-model="respostaAtual"
                                @change="proximaPergunta">
                                <v-radio
                                    v-for="opcao in perguntaAtual.opcoes"
                                    :key="opcao.resposta"
                                    :label="opcao.resposta"
                                    :value="opcao.resposta"></v-radio>
                            </v-radio-group>
                        </div>

                        <p
                            v-if="resultadoFinal"
                            class="tw-text-lg tw-font-bold tw-text-green-900">
                            Resultado: {{ resultadoFinal }}
                        </p>
                    </div>
                </v-window-item>

                <v-window-item value="Declaração de Validação">
                    <div>
                        <v-btn
                            size="large"
                            class="tw-mb-2 tw-mr-2 tw-bg-green-900 tw-text-white"
                            @click="downloadRequerimento"
                            >Download Requerimento</v-btn
                        >
                    </div>
                </v-window-item>

                <v-window-item value="Requerimento Espacos Experimentacao">
                    <div class="tw-bg-gray-100 tw-space-y-4 tw-rounded-lg tw-p-4">
                        <v-text-field
                            v-model="form.nome"
                            label="Nome"
                            required></v-text-field>
                        <v-text-field
                            v-model="form.email"
                            label="Contacto de E-mail"
                            type="email"
                            required></v-text-field>
                        <v-text-field
                            v-model="form.subproduto"
                            label="Subproduto"
                            required></v-text-field>
                        <v-text-field
                            v-model="form.utilizacaoFinal"
                            label="Utilização Final"
                            required></v-text-field>
                        <v-text-field
                            v-model="form.periodo"
                            label="Período de Utilização"
                            required></v-text-field>
                        <v-divider></v-divider>
                        <v-text-field
                            v-model="form.empresaOrigem"
                            label="Empresa de Produção"
                            required></v-text-field>
                        <v-text-field
                            v-model="form.moradaEmpresaOrigem"
                            label="Morada da Empresa de Produção"
                            required></v-text-field>
                        <v-text-field
                            v-model="form.codigoPostalEmpresaOrigem"
                            label="Código Postal da Empresa de Produção"
                            required></v-text-field>
                        <v-text-field
                            v-model="form.localidadeEmpresaOrigem"
                            label="Localidade da Empresa de Produção"
                            required></v-text-field>
                        <v-text-field
                            v-model="form.paisEmpresaOrigem"
                            label="País da Empresa de Produção"
                            required></v-text-field>
                        <v-divider></v-divider>
                        <v-text-field
                            v-model="form.empresaDestino"
                            label="Empresa de Utilização"
                            required></v-text-field>
                        <v-text-field
                            v-model="form.moradaEmpresaDestino"
                            label="Morada da Empresa de Utilização"
                            required></v-text-field>
                        <v-text-field
                            v-model="form.codigoPostalEmpresaDestino"
                            label="Código Postal da Empresa de Utilização"
                            required></v-text-field>
                        <v-text-field
                            v-model="form.localidadeEmpresaDestino"
                            label="Localidade da Empresa de Utilização"
                            required></v-text-field>
                        <v-text-field
                            v-model="form.paisEmpresaDestino"
                            label="País da Empresa de Utilização"
                            required></v-text-field>
                        <v-divider></v-divider>
                        <v-text-field
                            v-model="form.empresaIntermedia"
                            label="Empresa de Processamento (Opcional)"></v-text-field>
                        <v-select
                            v-model="form.laboratorio"
                            label="Laboratórios/Centros Tecnológicos"
                            :items="laboratorios"
                            multiple
                            required></v-select>
                        <v-textarea
                            v-model="form.descricaoProcesso"
                            label="Descrição do Processo Produtivo"
                            required></v-textarea>
                        <v-textarea
                            v-model="form.descricaoTestes"
                            label="Descrição dos Testes e Espaço de Experimentação"
                            required></v-textarea>
                        <v-textarea
                            v-model="form.desmantelamento"
                            label="Descrição da Operação de Desmantelamento (Opcional)"></v-textarea>
                        <div class="tw-mt-4 tw-flex tw-flex-row tw-justify-between">
                            <v-btn
                                color="green-900"
                                @click="gerarPDF"
                                >Gerar PDF</v-btn
                            >

                            <v-btn color="green-900">Anexar PDF</v-btn>
                        </div>
                    </div>
                </v-window-item>
            </v-window>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { ref } from 'vue';
    const selectedForm = ref('Questionário Inicial');

    const isEditing = ref(false);
    const isLoading = ref(false);

    const formData = ref({
        field1: '',
        field2: '',
    });

    // ------------------- questionario 1 -------------------

    const respostaAtual = ref(null);
    const indicePergunta = ref(0);
    const resultadoFinal = ref(null);

    const perguntas = [
        {
            texto: 'A substância é reutilizada no processo produtivo que lhe deu origem?',
            opcoes: [
                { resposta: 'Sim', resultado: 'Não Resíduo' },
                { resposta: 'Não', proxima: 1 },
            ],
        },
        {
            texto: 'A produção da substância é o objetivo primário no processo produtivo?',
            opcoes: [
                { resposta: 'Sim', resultado: 'Produto' },
                { resposta: 'Não', proxima: 2 },
            ],
        },
        {
            texto: 'A utilização posterior da substância é certa?',
            opcoes: [
                { resposta: 'Sim', proxima: 3 },
                { resposta: 'Não', resultado: 'Resíduo' },
            ],
        },
        {
            texto: 'A substância está pronta para utilização sem outra transformação?',
            opcoes: [
                { resposta: 'Sim', proxima: 4 },
                { resposta: 'Não', proxima: 5 },
            ],
        },
        {
            texto: 'O processamento é uma prática industrial normal?',
            opcoes: [
                { resposta: 'Sim', proxima: 6 },
                { resposta: 'Não', resultado: 'Resíduo' },
            ],
        },
        {
            texto: 'A produção da substância é parte integrante do processo produtivo?',
            opcoes: [
                { resposta: 'Sim', proxima: 6 },
                { resposta: 'Não', resultado: 'Resíduo' },
            ],
        },
        {
            texto: 'A substância cumpre com os requisitos da matéria-prima que vai substituir?',
            opcoes: [
                { resposta: 'Sim', resultado: 'Possível Subproduto' },
                { resposta: 'Não', resultado: 'Resíduo' },
            ],
        },
    ];

    const perguntaAtual = ref(perguntas[0]);
    const perguntasRespondidas = ref([]);

    const proximaPergunta = () => {
        const opcaoSelecionada = perguntaAtual.value.opcoes.find((o) => o.resposta === respostaAtual.value);
        perguntasRespondidas.value.push({ texto: perguntaAtual.value.texto, resposta: respostaAtual.value });

        if (opcaoSelecionada.resultado) {
            resultadoFinal.value = opcaoSelecionada.resultado;
            respostaAtual.value = null;
            return;
        }

        if (opcaoSelecionada.proxima !== undefined) {
            perguntaAtual.value = perguntas[opcaoSelecionada.proxima];
            respostaAtual.value = null;
            return;
        }
    };

    // ------------------- questionario 2 -------------------

    import jsPDF from 'jspdf';
    import { title } from 'process';
    import { text } from 'stream/consumers';

    const form = ref({
        nome: 'Tintex Textiles, S.A., localizada na Zona Ind. Campos, Pólo 1, 4920-247 V. N. de Cerveira, Portugal',
        email: 'info@tintextextiles.com',
        subproduto: 'Engaço de uva',
        utilizacaoFinal: 'Material têxtil revestido',
        periodo: '6 meses',
        empresaOrigem: 'Quinta de Soalheiro, Lda',
        moradaEmpresaOrigem: 'Lugar da Charneca',
        codigoPostalEmpresaOrigem: '4960-010',
        localidadeEmpresaOrigem: 'Alvaredo',
        paisEmpresaOrigem: 'Portugal',
        empresaDestino: 'Tintex Textiles, S.A.',
        moradaEmpresaDestino: 'Zona Ind. Campos, Pólo 1',
        codigoPostalEmpresaDestino: '4920-247',
        localidadeEmpresaDestino: 'V. N. de Cerveira',
        paisEmpresaDestino: 'Portugal',
        empresaIntermedia: 'O potencial subproduto não será sujeito a processamento adicional numa empresa intermédia antes da utilização final pela Tintex Textiles.',
        laboratorio: [],
        descricaoProcesso:
            'O potencial subproduto tem a sua origem no processo produtivo de vinificação utilizado pela Quinta de Soalheiro. Durante o processo de prensagem, a uva é alimentada numa prensa mecânica, resultando numa separação do substrato em sumo de uva (produto principal) e engaço de uva (subproduto).',
        descricaoTestes:
            'O processo de adequação do potencial subproduto será iniciado com o transporte do mesmo das instalações da Quinta do Soalheiro para as instalações da Tintex. Na Tintex será sujeito a uma secagem inicial através de um método similar ao aplicado em estufa até apresentar uma percentagem de humidade inferior a 10%. Nesta etapa não se verifica a deposição de líquidos e devido ao sistema de extração de gases também não ocorre a geração de odores.',
        desmantelamento: 'Não haverá desmantelamento dos equipamentos usados no projeto piloto, uma vez que estes são necessários para o processo de aplicação de revestimentos têxteis da Tintex.',
    });

    const laboratorios = ref(['Laboratório A', 'Laboratório B', 'Centro Tecnológico C']);

    const gerarPDF = () => {
        const doc = new jsPDF();
        const pageWidth = doc.internal.pageSize.width;
        const pageHeight = doc.internal.pageSize.height;
        const marginLeft = 42;
        const marginRight = 32;
        const maxWidth = pageWidth - marginLeft - marginRight;
        let startY = 29;
        const lineHeight = 7;

        const addTextWithPagination = (text, isBold = false) => {
            doc.setFont('helvetica', isBold ? 'normal' : 'normal');

            const lines = doc.splitTextToSize(text, maxWidth);
            lines.forEach((line) => {
                if (startY + lineHeight > pageHeight - 40) {
                    doc.addPage();
                    startY = 20;
                }
                doc.text(line, marginLeft, startY);
                startY += lineHeight;
            });

            startY += 5;
        };

        doc.setFont('helvetica', 'normal');
        doc.setFontSize(9);
        const rightAlignedText = 'Exmo. Sr. Presidente da Agência Portuguesa do Ambiente';
        const textWidth = doc.getTextWidth(rightAlignedText);
        doc.text(rightAlignedText, pageWidth - textWidth - marginRight, startY);
        startY += 20;

        doc.setFont('helvetica', 'bold');
        doc.text('Requerimento', pageWidth / 2, startY, { align: 'center' });
        startY += 15;

        const introducao =
            `A ${form.value.empresaDestino}, localizada na ${form.value.moradaEmpresaDestino}, ` +
            `${form.value.codigoPostalEmpresaDestino}, ${form.value.localidadeEmpresaDestino}, ` +
            `${form.value.paisEmpresaDestino} e ${form.value.email}, vem requerer a V. Exa., ` +
            `nos termos do n.º11 do artigo 91.º do Anexo I do Decreto-Lei n.º102-D/2020, de 10 de dezembro, ` +
            `na sua atual redação, autorização para a constituição de espaço de experimentação e de inovação ` +
            `para testar a utilização de ${form.value.subproduto} em ${form.value.utilizacaoFinal}, ` +
            `pelo período de ${form.value.periodo}. Para o efeito junta a seguinte informação:`;

        addTextWithPagination(introducao);

        const tab = '                 ';

        const sections = [
            {
                title: 'a. Identificação da empresa e estabelecimento onde é produzido o potencial subproduto;',
                text: `${tab} i. ${form.value.empresaOrigem}\n ${tab}ii. ${form.value.moradaEmpresaOrigem}, ${form.value.codigoPostalEmpresaOrigem} ${form.value.localidadeEmpresaOrigem}, ${form.value.paisEmpresaOrigem}`,
            },
            {
                title: 'b. Identificação da empresa e estabelecimento onde se vai proceder à utilização do potencial subproduto;',
                text: `${tab} i. ${form.value.empresaDestino}\n ${tab}ii. ${form.value.moradaEmpresaDestino}, ${form.value.codigoPostalEmpresaDestino} ${form.value.localidadeEmpresaDestino}, ${form.value.paisEmpresaDestino}`,
            },
            {
                title: 'c. Caso aplicável: Identificação da empresa e estabelecimento de processamento intermédio do potencial subproduto antes da utilização final;',
                text: `${tab} i. ${form.value.empresaIntermedia || 'N/A'}`,
            },
            {
                title: 'd. Identificação dos laboratórios colaborativos e/ou centros tecnológicos envolvidos e que atuem na área de produção e destino do potencial subproduto;',
                text: `${tab}i. ${form.value.laboratorio.join(', ') || 'Nenhum especificado'}`,
            },
            {
                title: 'e. Descrição do processo produtivo que dá origem ao potencial subproduto, integrando a informação que comprove o cumprimento da alínea c) do n.º 1 do art.º 91;',
                text: `${tab} i. ${form.value.descricaoProcesso},`,
            },
            {
                title: 'f. Descrição do espaço de experimentação e dos testes a realizar, incluindo eventuais protocolos, contratos ou outros documentos que acautelem as 4 fases:',
                text: `${tab} i. ${form.value.descricaoTestes} `,
            },
            {
                title: 'g. Caso aplicável: descrição da operação de desmantelamento do projeto piloto encaminhamento dos resíduos produzidos de acordo com a legislação em vigor, caso não exista viabilidade para a utilização prevista.',
                text: `${tab} i. ${form.value.desmantelamento || 'N/A'}`,
            },
        ];

        sections.forEach((section) => {
            addTextWithPagination(section.title, true);
            addTextWithPagination(section.text);
        });

        startY += 20;
        doc.text('Pede deferimento', marginLeft, startY);
        startY += 15;

        doc.text('Assinatura', marginLeft, startY);
        doc.line(marginLeft + 25, startY, pageWidth - marginRight, startY);
        startY += 15;

        doc.text('Data,', marginLeft, startY);
        doc.line(marginLeft + 15, startY, pageWidth - marginRight, startY);

        doc.save('Requerimento_Espacos_Experimentacao.pdf');
    };

    function downloadRequerimento() {
        const link = document.createElement('a');
        link.href = '/Requerimento_EspaçosExperimentação.pdf';
        link.download = 'Requerimento_EspaçosExperimentação.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    function onSubmit() {
        if (isEditing.value) {
            console.log('Guardando os dados:', formData.value);
        }
        isEditing.value = !isEditing.value;
    }
</script>
