<template>
    <v-app-bar
        color="primary"
        :height="lgAndUp ? 100 : 60">
        <template v-slot:append>
            <v-app-bar-nav-icon
                v-if="!lgAndUp"
                @click.stop="isSidebarOpen = !isSidebarOpen"></v-app-bar-nav-icon>
        </template>
        <div class="tw-mx-auto tw-grow tw-grid-cols-12 tw-items-center tw-px-8 lg:tw-grid 2xl:tw-px-32">
            <router-link
                :to="{ name: 'Homepage' }"
                class="tw-col-span-2">
                <img
                    class="tw-h-full tw-max-h-8 lg:tw-max-h-14"
                    src="@/assets/images/logo-dark-green.svg" />
            </router-link>

            <div class="tw-col-span-8 tw-hidden tw-justify-center tw-gap-2 lg:tw-flex">
                <v-btn
                    v-for="route in routes"
                    :key="route.route"
                    variant="text"
                    :active="$route.name == route.route"
                    :class="{
                        'tw-underline': $route.name == route.route,
                    }"
                    :ripple="false"
                    :to="{ name: route.route }"
                    class="navbar-links tw-text-xl tw-font-normal tw-lowercase"
                    color="secondary"
                    :text="route.name"></v-btn>
            </div>
            <div class="tw-col-span-2 tw-hidden tw-items-center tw-gap-2 tw-place-self-end lg:tw-flex">
                <v-btn
                    :to="{ name: 'ProfileInfo' }"
                    icon="mdi-account-outline"
                    class="tw-text-xl"></v-btn>
                <v-btn
                    :to="{ name: 'Orders' }"
                    icon="mdi-package-variant-closed"
                    class="tw-text-xl"></v-btn>
                <v-menu
                    open-on-hover
                    open-on-click
                    open-on-focus>
                    <template v-slot:activator="{ props }">
                        <v-btn
                            color="black"
                            v-bind="props">
                            Idioma
                        </v-btn>
                    </template>

                    <v-list>
                        <v-list-item
                            @click="$i18n.locale = locale"
                            v-for="(locale, index) in $i18n.availableLocales"
                            :key="index">
                            <v-list-item-title>{{ t(`locales.${locale}`) }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </div>
    </v-app-bar>
    <v-navigation-drawer
        location="right"
        v-model="isSidebarOpen"
        color="pink"
        class="sidebar"
        temporary>
        <v-list
            nav
            class="tw-my-6">
            <v-list-item
                v-for="route in routes"
                :key="route.route"
                :title="route.name"
                :to="{ name: route.route }"></v-list-item>
        </v-list>
        <div class="tw-flex tw-justify-center tw-gap-4">
            <v-btn
                color="green-900"
                :to="{ name: 'ProfileInfo' }"
                icon="mdi-account-outline"
                class="tw-text-xl"></v-btn>
            <v-btn
                color="green-900"
                icon="mdi-package-variant-closed"
                class="tw-text-xl"></v-btn>
        </div>
        <div class="tw-mt-auto tw-text-center">
            <v-menu open-on-hover>
                <template v-slot:activator="{ props }">
                    <v-btn
                        color="black"
                        v-bind="props">
                        Idioma
                    </v-btn>
                </template>

                <v-list>
                    <v-list-item
                        @click="$i18n.locale = locale"
                        v-for="(locale, index) in $i18n.availableLocales"
                        :key="index">
                        <v-list-item-title>{{ t(`locales.${locale}`) }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>
    </v-navigation-drawer>
</template>

<script setup lang="ts">
    import { ref, computed } from 'vue';
    import { useDisplay } from 'vuetify';
    import { useI18n } from 'vue-i18n';
    import { watch } from 'vue';
    import { useAuthStore } from '@/store/auth';

    const isSidebarOpen = ref(false);
    const { lgAndUp } = useDisplay();
    const authStore = useAuthStore();
    const { t, locale } = useI18n();


    const routes = computed(() => [
        {
            name: t('navbar.marketplace'),
            route: 'Marketplace',
        },
        {
            name: t('navbar.suppliers'),
            route: 'Suppliers',
        },
        {
            name: t('navbar.partners'),
            route: 'Partners',
        },
        {
            name: t('navbar.documentation'),
            route: 'Documentation',
        },
        { 
            name: t('navbar.interfacecenters'),
            route: 'InterfaceCenters',
        },
        {
            name: t('navbar.news'),
            route: 'News',
        },
        
    ]);

    watch(locale, (newValue) => {
        authStore.setLocale(newValue);
    });
</script>

<style lang="scss">
    .navbar-links .v-btn__overlay {
        display: none;
    }

    .sidebar {
        @apply tw-w-full #{!important};

        .v-navigation-drawer__content {
            display: flex;
            flex-direction: column;
            padding-bottom: 2rem;
        }

        .v-list-item-title {
            @apply tw-py-2 tw-text-center tw-text-2xl tw-font-normal #{!important};
        }
    }
</style>
