// Composables
import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '@/store/auth';

import Home from '@/views/Home.vue';

// AUTH
import Auth from '@/views/Auth/Auth.vue';
import Login from '@/views/Auth/Login.vue';
import ForgotPassword from '@/views/Auth/ForgotPassword.vue';
import ResetPassword from '@/views/Auth/ResetPassword.vue';
import Homepage from '@/views/Homepage.vue';
import Marketplace from '@/views/Products/Marketplace.vue';
import Suppliers from '@/views/Suppliers/Suppliers.vue';
import Partners from '@/views/Partners/Partners.vue';
import About from '@/views/About.vue';
import Contact from '@/views/Contacts.vue';
import News from '@/views/News/News.vue';
import Profile from '@/views/Profile/Profile.vue';
import ProfileInfo from '@/views/Profile/Info.vue';
import Forms from '@/views/Profile/Forms.vue';
import ProfileFavorites from '@/views/Profile/Favorites.vue';
import Product from '@/views/Products/Product.vue';
import Supplier from '@/views/Suppliers/Supplier.vue';
import Partner from '@/views/Partners/Partner.vue';
import Register from '@/views/Auth/Register.vue';
import Notifications from '@/views/notifications.vue';
import Orders from '@/views/Orders/Orders.vue';
import History from '@/views/Orders/History.vue';
import ActiveOrders from '@/views/Orders/ActiveOrders.vue';
import Shipping from '@/views/Shipping-Company/Shipping.vue';
import Shippings from '@/views/Shipping-Company/Shippings.vue';
import ProfileSugest from '@/views/Profile/Sugest.vue';
import VendedorSide from '@/views/VendedorSide.vue';
import CardVendorSide from '@/views/CardVendorSide.vue';
import MyProducts from '@/views/Profile/MyProducts.vue';
import Documentation from '@/views/Documentation/Documentation.vue';
import InterfaceCenters from '@/views/InterfaceCenters/InterfaceCenters.vue';
import CentrosTecnologicos from '@/views/CentrosTecnologicos/CentrosTecnologicos.vue';
import CardPedidosCentrosTecnologicos from '@/views/CentrosTecnologicos/CardPedidosCentrosTecnologicos.vue';
import DetalhesPedidosCentrosTecnologicos from '@/views/CentrosTecnologicos/DetalhesPedidosCentrosTecnologicos.vue';

const routes = [
    {
        path: '/auth',
        name: 'Auth',
        component: Auth,
        redirect: { name: 'Login' },
        beforeEnter: (to, from, next) => {
            const authStore = useAuthStore();
            if (authStore.isAuthenticated) {
                next({ name: 'Home' });
            } else {
                next();
            }
        },
        children: [
            {
                path: 'login',
                name: 'Login',
                component: Login,
            },
            {
                path: 'forgot-password',
                name: 'ForgotPassword',
                component: ForgotPassword,
            },
            {
                path: 'reset-password/:token',
                name: 'ResetPassword',
                component: ResetPassword,
            },
            {
                path: 'register',
                name: 'Register',
                component: Register,
            },
        ],
        meta: {
            authRequired: false,
        },
    },

    {
        path: '/',
        component: Home,
        name: 'Home',
        redirect: { name: 'Homepage' },
        children: [
            {
                path: '',
                name: 'Homepage',
                component: Homepage,
                meta: {
                    navbar: true,
                    footer: true,
                    bottombar: true,
                    breadcrumbs: false,
                },
            },
            {
                path: 'marketplace',
                name: 'Marketplace',
                component: Marketplace,
                meta: {
                    bottombar: true,
                    navbar: true,
                },
            },
            {
                path: '/marketplace/produto/:id',
                name: 'Product',
                component: Product,
                meta: {
                    bottombar: false,
                },
            },

            {
                path: 'suppliers',
                name: 'Suppliers',
                component: Suppliers,
            },
            {
                path: '/suppliers/:id',
                name: 'Supplier',
                component: Supplier,
                meta: {
                    bottombar: true,
                },
            },
            {
                path: 'partners',
                name: 'Partners',
                component: Partners,
            },
            {
                path: '/partners/:id',
                name: 'Partner',
                component: Partner,
                meta: {
                    bottombar: true,
                },
            },
            {
                path: 'documentation',
                name: 'Documentation',
                component: Documentation,
            },
            {
                path: 'interfaceCenters',
                name: 'InterfaceCenters',
                component: InterfaceCenters,
            },
            {
                path: 'centrosTecnologicos',
                name: 'CentrosTecnologicos',
                component: CentrosTecnologicos,
            },
            {
                path: '/centrosTecnologicos/pedido',
                name: 'DetalhesPedidosCentrosTecnologicos',
                component: DetalhesPedidosCentrosTecnologicos,
                meta: {
                    bottombar: false,
                },
            },
            /*{
                path: 'shippings',
                name: 'Shippings',
                component: Shippings,
            },
            {
                path: '/shippings/:id',
                name: 'Shipping',
                component: Shipping,
                meta: {
                    bottombar: true,
                },
            },
            
            */

            {
                path: 'about',
                name: 'About',
                component: About,
            },
            {
                path: 'contact',
                name: 'Contact',
                component: Contact,
            },
            {
                path: 'news',
                name: 'News',
                component: News,
            },

            {
                path: '/VendedorSide',
                name: 'VendedorSide',
                component: VendedorSide,
                meta: {
                    navbar: true,
                    footer: true,

                    breadcrumbs: false,
                },
            },
            {
                path: '/CardVendorSide',
                name: 'CardVendorSide',
                component: CardVendorSide,
                meta: {
                    navbar: true,
                    footer: true,

                    breadcrumbs: false,
                },
            },

            {
                path: 'profile',
                name: 'Profile',
                component: Profile,
                beforeEnter: (to, from, next) => {
                    const authStore = useAuthStore();
                    if (!authStore.isAuthenticated) {
                        next({ name: 'Login' });
                    } else {
                        next();
                    }
                },
                children: [
                    {
                        path: 'info',
                        name: 'ProfileInfo',
                        component: ProfileInfo,
                    },
                    {
                        path: 'notifications',
                        name: 'Notifications',
                        component: Notifications,
                    },
                    {
                        path: 'favorites',
                        name: 'ProfileFavorites',
                        component: ProfileFavorites,
                    },
                    {
                        path: 'sugest',
                        name: 'ProfileSugest',
                        component: ProfileSugest,
                    },
                    {
                        path: 'myProducts',
                        name: 'MyProducts',
                        component: MyProducts,
                    },
                    {
                        path: 'forms',
                        name: 'Forms',
                        component: Forms,
                    },
                ],
            },
            // {
            //     path: 'notifications',
            //     name: 'Notifications',
            //     component: Notifications,
            //     beforeEnter: (to, from, next) => {
            //         const authStore = useAuthStore();
            //         if (!authStore.isAuthenticated) {
            //             next({ name: 'Login' });
            //         } else {
            //             next();
            //         }
            //     },
            //     children: [

            //         {
            //             path: 'orders_Notifications',
            //             name: 'ProfileOrders_Notifications',
            //             component: ProfileOrders_Notifications,
            //         },
            //         {
            //             path: 'history_Notifications',
            //             name: 'ProfileHistory_Notifications',
            //             component: ProfileHistory_Notifications,
            //         },

            //     ],
            // },
            {
                path: 'orders',
                name: 'Orders',
                component: Orders,
                beforeEnter: (to, from, next) => {
                    const authStore = useAuthStore();
                    if (!authStore.isAuthenticated) {
                        next({ name: 'Login' });
                    } else {
                        next();
                    }
                },
                children: [
                    {
                        path: 'history',
                        name: 'OrdersHistory',
                        component: History,
                    },
                    {
                        path: 'active-orders',
                        name: 'ActiveOrders',
                        component: ActiveOrders,
                    },
                ],
            },
        ],
    },
] as Readonly<RouteRecordRaw[]>;

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0 };
        }
    },
});

router.beforeEach((to, from, next) => {
    // const authRequired = to.matched.some((route) => route.meta.authRequired);

    // if (authRequired) {
    //     const authStore = useAuthStore();
    //     if (!authStore.isAuthenticated) {
    //         next({ name: 'Login' });
    //     } else {
    //         next();
    //     }
    // } else
    next();
});

export default router;
