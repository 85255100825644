<template>
    <div class="tw-flex tw-flex-col tw-items-center tw-gap-4 tw-px-[var(--padding-left)] tw-pb-[var(--padding-top)]">
        <!-- Botão para abrir o formulário -->
        <v-btn color="green-900" @click="mostrarFormulario = true">Requerimento_EspaçosExperimentação</v-btn>

        <!-- Formulário (Aparece apenas quando mostrarFormulario for true) -->
        <v-dialog v-model="mostrarFormulario" max-width="600px">
            <v-card>
                <v-card-title>Requerimento de Espaços de Experimentação</v-card-title>
                <v-card-text>
                    <div class="tw-flex tw-flex-col tw-gap-4">
                        <v-text-field v-model="form.nome" label="Nome" required></v-text-field>
                        <v-text-field v-model="form.email" label="Contacto de E-mail" type="email" required></v-text-field>
                        <v-text-field v-model="form.subproduto" label="Subproduto" required></v-text-field>
                        <v-text-field v-model="form.utilizacaoFinal" label="Utilização Final" required></v-text-field>
                        <v-text-field v-model="form.periodo" label="Período de Utilização" required></v-text-field>
                        <v-text-field v-model="form.empresaOrigem" label="Empresa de Produção" required></v-text-field>
                        <v-text-field v-model="form.empresaDestino" label="Empresa de Utilização" required></v-text-field>
                        <v-text-field v-model="form.empresaIntermedia" label="Empresa de Processamento (Opcional)"></v-text-field>

                        <v-select v-model="form.laboratorio" label="Laboratórios/Centros Tecnológicos" :items="laboratorios" multiple required></v-select>

                        <v-textarea v-model="form.descricaoProcesso" label="Descrição do Processo Produtivo" required></v-textarea>
                        <v-textarea v-model="form.descricaoTestes" label="Descrição dos Testes e Espaço de Experimentação" required></v-textarea>
                        <v-textarea v-model="form.desmantelamento" label="Descrição da Operação de Desmantelamento (Opcional)"></v-textarea>
                    </div>
                </v-card-text>
                <v-card-actions class="tw-flex tw-justify-between">
                    <v-btn color="red" @click="mostrarFormulario = false">Fechar</v-btn>
                    <v-btn color="green-900" @click="gerarPDF">Gerar PDF</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import jsPDF from 'jspdf';

// Controla a visibilidade do formulário
const mostrarFormulario = ref(false);

const form = ref({
    nome: 'Tintex Textiles, S.A., localizada na Zona Ind. Campos, Pólo 1, 4920-247 V. N. de Cerveira, Portugal',
    email: 'info@tintextextiles.com',
    subproduto: 'Engaço de uva',
    utilizacaoFinal: 'Material têxtil revestido',
    periodo: '6 meses',
    empresaOrigem: 'Quinta de Soalheiro, Lda, Lugar da Charneca, 4960-010 Alvaredo, Portugal',
    empresaDestino: 'Tintex Textiles, S.A., Zona Ind. Campos, Pólo 1, 4920-247 V. N. de Cerveira, Portugal',
    empresaIntermedia: 'O potencial subproduto não será sujeito a processamento adicional numa empresa intermédia antes da utilização final pela Tintex Textiles.',
    laboratorio: [],
    descricaoProcesso: 'O potencial subproduto tem a sua origem no processo produtivo de vinificação utilizado pela Quinta de Soalheiro. Durante o processo de prensagem, a uva é alimentada numa prensa mecânica, resultando numa separação do substrato em sumo de uva (produto principal) e engaço de uva (subproduto).',
    descricaoTestes: 'O processo de adequação do potencial subproduto será iniciado com o transporte do mesmo das instalações da Quinta do Soalheiro para as instalações da Tintex. Na Tintex será sujeito a uma secagem inicial através de um método similar ao aplicado em estufa até apresentar uma percentagem de humidade inferior a 10%. Nesta etapa não se verifica a deposição de líquidos e devido ao sistema de extração de gases também não ocorre a geração de odores.',
    desmantelamento: 'Não haverá desmantelamento dos equipamentos usados no projeto piloto, uma vez que estes são necessários para o processo de aplicação de revestimentos têxteis da Tintex.',
});

const laboratorios = ref(["Laboratório A", "Laboratório B", "Centro Tecnológico C"]);

const gerarPDF = () => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;
    const marginLeft = 42;
    const marginRight = 32;
    const maxWidth = pageWidth - marginLeft - marginRight;
    let startY = 29;
    const lineHeight = 7;

    const addTextWithPagination = (text, isBold = false) => {
        doc.setFont("helvetica", isBold ? "bold" : "normal");

        const lines = doc.splitTextToSize(text, maxWidth);
        lines.forEach((line) => {
            if (startY + lineHeight > pageHeight - 40) {
                doc.addPage();
                startY = 20;
            }
            doc.text(line, marginLeft, startY);
            startY += lineHeight;
        });

        startY += 5;
    };

    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    const rightAlignedText = "Exmo. Sr. Presidente da Agência Portuguesa do Ambiente";
    const textWidth = doc.getTextWidth(rightAlignedText);
    doc.text(rightAlignedText, pageWidth - textWidth - marginRight, startY);
    startY += 20;

    doc.setFont("helvetica", "bold");
    doc.text("Requerimento", pageWidth / 2, startY, { align: "center" });
    startY += 15;

    const sections = [
        { title: "a. Identificação da empresa e estabelecimento onde é produzido o potencial subproduto", text: `i. ${form.value.empresaOrigem}` },
        { title: "b. Identificação da empresa e estabelecimento onde se vai proceder à utilização do potencial subproduto", text: `i. ${form.value.empresaDestino}` },
        { title: "c. Identificação da empresa e estabelecimento de processamento intermédio (Opcional)", text: `i. ${form.value.empresaIntermedia || 'N/A'}` },
        { title: "d. Laboratórios/Centros Tecnológicos Envolvidos", text: `i. ${form.value.laboratorio.join(", ") || 'Nenhum especificado'}` },
        { title: "e. Descrição do Processo Produtivo", text: form.value.descricaoProcesso },
        { title: "f. Descrição dos Testes e Espaço de Experimentação", text: form.value.descricaoTestes },
    ];

    sections.forEach(section => {
        addTextWithPagination(section.title, true);
        addTextWithPagination(section.text);
    });

    if (form.value.desmantelamento) {
        addTextWithPagination("g. Descrição da Operação de Desmantelamento (Opcional)", true);
        addTextWithPagination(form.value.desmantelamento);
    }

    startY += 20;
    doc.text("Pede deferimento", marginLeft, startY);
    startY += 15;

    doc.text("Assinatura", marginLeft, startY);
    doc.line(marginLeft + 25, startY, pageWidth - marginRight, startY);
    startY += 15;

    doc.text("Data,", marginLeft, startY);
    doc.line(marginLeft + 15, startY, pageWidth - marginRight, startY);

    doc.save("Requerimento_Espacos_Experimentacao.pdf");
};
</script>
