<template>
    <div class="tw-flex max-lg:tw-flex-col max-lg:tw-justify-center lg:tw-items-start lg:tw-gap-8 lg:tw-px-[var(--padding-left)] 2xl:tw-gap-16">
        <v-carousel
            class="tw-w-full tw-border tw-border-gray-300 max-lg:tw-mx-auto max-lg:tw-max-h-96 max-sm:tw-w-full sm:tw-mx-0 sm:tw-max-w-full md:tw-rounded-4xl lg:tw-max-w-xs 2xl:tw-max-w-md"
            show-arrows="hover"
            :model-value="0"
            >
            <v-carousel-item
                v-for="(picture, index) in productImages"
                :key="index"
                :src="picture"
                cover>                
            </v-carousel-item>
        </v-carousel>

        <div class="tw-relative tw-flex tw-flex-col tw-bg-pink max-lg:tw-mt-6 max-md:-tw-mt-8 max-md:tw-rounded-4xl max-md:tw-p-6 lg:tw-items-start">
            <h1 class="tw-text-2xl tw-text-green-900 max-md:tw-mt-4 md:tw-mx-[var(--padding-left)] md:tw-text-3xl lg:tw-ml-0 2xl:tw-text-5xl">{{ product?.name }}</h1>

            <div class="tw-mt-2 tw-flex md:tw-mx-[var(--padding-left)] lg:tw-ml-0">
                <v-icon color="orange-300">mdi-star</v-icon>
                <v-icon color="orange-300">mdi-star</v-icon>
                <v-icon color="orange-300">mdi-star</v-icon>
                <v-icon color="orange-300">mdi-star</v-icon>
                <v-icon color="grey">mdi-star</v-icon>
                <p>4.0</p>
            </div>

            <div class="tw-flex tw-flex-row">
                <router-link :to="`/suppliers/${vendorId}`">
                    <v-container class="tw-mt-2 tw-flex tw-flex-row tw-items-center tw-pl-0">
                        <io-img
                            :aspect-ratio="1"
                            contain
                            class="avatar tw-mr-3 tw-h-11 tw-w-11 tw-rounded-full tw-border tw-border-gray-300 tw-object-cover max-lg:tw-mx-auto md:tw-ml-[var(--padding-left)] md:tw-mr-2 lg:tw-ml-0"
                            :media="vendorPicture"
                            format="small"
                            content-type="Product"></io-img>
                        <label> {{ product?.orders }} </label>
                    </v-container>
                </router-link>
            </div>

            <div class="tw-mt-2 tw-flex tw-items-center md:tw-mx-[var(--padding-left)] lg:tw-ml-0">
                <v-icon
                    class="tw-rounded-lg tw-bg-secondary tw-p-4"
                    color="white">
                    mdi-map-marker
                </v-icon>
                <h4 class="tw-pl-2 tw-text-lg tw-text-green-900 2xl:tw-text-2xl">Porto, Portugal</h4>
            </div>

            <h6 class="tw-mt-4 tw-pt-2 tw-text-lg tw-font-semibold tw-text-green-900 md:tw-mx-[var(--padding-left)] lg:tw-ml-0 2xl:tw-text-xl">{{ $t('views.suppliers.supplier.description') }}</h6>
            <h6 class="tw-text-green-900 md:tw-mx-[var(--padding-left)] lg:tw-ml-0 2xl:tw-text-xl">{{ product?.description }}</h6>

            <v-container class="tw-pt-5">
                <v-row>
                    <v-col class="tw-flex tw-flex-row tw-items-center tw-gap-1">
                        <v-icon> mdi-hanger </v-icon>
                        <span class="tw-text-sm tw-text-green-900"> Tipo: </span>
                        <p class="tw-text-sm tw-text-green-900">{{ $t('views.products.textiles') }}</p>
                    </v-col>
                    <v-col class="tw-flex tw-flex-row tw-items-center tw-gap-1">
                        <v-icon> mdi-leaf-circle </v-icon>
                        <p class="tw-text-sm tw-text-green-900">{{ $t('views.products.sustainability') }}</p>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="tw-flex tw-flex-row tw-items-center tw-gap-1">
                        <v-icon> mdi-calendar-check </v-icon>

                        <span class="tw-text-sm tw-text-green-900"> Criado a: </span>
                        <p class="tw-text-sm tw-text-green-900">{{ formattedCreatedAt }}</p>
                    </v-col>
                    <v-col class="tw-flex tw-flex-row tw-items-center tw-gap-1">
                        <v-icon> mdi-truck-delivery </v-icon>

                        <span class="tw-text-sm tw-text-green-900"> Atualizado a: </span>
                        <p class="tw-text-sm tw-text-green-900">{{ formattedUpdatedAt }}</p>
                    </v-col>
                </v-row>
            </v-container>

            <v-divider></v-divider>

            <div
                v-if="product?.keywords && product.keywords.length > 0"
                class="tw-mb-2 tw-mt-4">
                <span
                    class="tw-border-gray-500 tw-mr-2 tw-w-max tw-flex-wrap tw-rounded-md tw-bg-primary tw-p-2"
                    v-for="(slug, index) in product.keywords.split(',').map((keyword) => keyword.trim())"
                    :key="index">
                    {{ slug }}
                </span>
                
    
            </div>

            <div class="tw-w-full tw-items-center tw-justify-between tw-bg-pink ">
                <div
                    class="tw-z-10 tw-flex tw-gap-4 max-lg:tw-fixed max-lg:tw-bottom-0 max-lg:tw-left-0 max-lg:tw-flex max-lg:tw-w-full max-lg:tw-items-center max-lg:tw-justify-between max-lg:tw-border-t-2 max-lg:tw-border-solid max-lg:tw-border-gray-300 max-lg:tw-bg-pink max-lg:tw-p-4 lg:tw-mt-6 lg:tw-flex lg:tw-flex-col">
                    
                    <!--
                    
                    <div class="tw-order-3 tw-flex max-md:tw-flex max-md:tw-flex-col lg:tw-order-1">
                        <v-icon class="max-lg:tw-hidden lg:tw-text-black">{{ 'mdi-truck' }}</v-icon>
                        <div class="tw-flex tw-flex-row">
                            <p class="pl-3 xl:text-xs tw-text-green-900">{{ $t('views.products.previsionTilldelivery') }} -</p>
                            <p class="pl-3 xl:text-xs tw-text-green-900y">13</p>
                        </div>
                    </div>

                    -->
                   

                    <!--  POP UP DO BOTAO SOLICITAR  -->

                    <div class="tw-justify-left tw-order-2 tw-flex tw-items-center tw-gap-4">
                        <v-dialog
                            v-model="dialog"
                            width="auto"
                            height="auto"
                            scrollable
                            class="custom-dialog">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    class="tw-bg-green-900 tw-px-12 tw-text-sm tw-font-bold tw-text-white"
                                    size="x-large"
                                    v-bind="props">
                                     {{ $t('tenho-interesse') }}
                                </v-btn>
                            </template>


                            <v-card class="tw-w-screen tw-p-8">
                                <v-card-title>
                                    <span class="text-h6 tw-font-bold tw-text-green-900">Interesse em {{ product?.name }} </span>
                                </v-card-title>
                                <v-card-text class="tw-pt-4">
                                    <label class="tw-pt-6"> Mensagem: </label>
                                    <v-textarea required></v-textarea>
                                   </v-card-text>
                                   <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="green-900"
                                        variant="text"
                                        @click="closeDialog"
                                        >{{ $t('views.suppliers.supplier.close') }}</v-btn
                                    >
                                    <v-btn
                                        color="green-900"
                                        variant="text"
                                        @click="closeDialog"
                                        >{{ $t('views.suppliers.supplier.contact') }}</v-btn
                                    >
                                </v-card-actions>
                            </v-card>
                      
                                                 

<!--
                            
  <v-card class="tw-w-screen tw-p-8">
                                <v-card-title>
                                    <span class="text-h5 tw-font-bold tw-text-green-900">Solicitar {{ product?.name }} </span>
                                </v-card-title>
                                <v-card-text class="tw-pt-4">
                                    <v-container class="tw-px-0 tw-pt-0">
                                        <v-stepper :items="['', '']">
                                            <template v-slot:item.1>
                                                <v-card flat>
                                                    <v-card-text>
                                                        <v-row>
                                                            <v-col
                                                                cols="12"
                                                                sm="6">
                                                                <label> Moradas utilizadas previamente: </label>
                                                            </v-col>

                                                            <v-col
                                                                cols="12"
                                                                sm="6">
                                                                <div class="text-subtitle-1 tw-text-green-900">Morada</div>
                                                                <v-text-field
                                                                    placeholder="Morada"
                                                                    v-model="address"
                                                                    required></v-text-field>

                                                                <div class="text-subtitle-1 tw-text-green-900">Codigo Postal</div>
                                                                <v-text-field
                                                                    placeholder="CodigoPostal"
                                                                    v-model="zip"
                                                                    required></v-text-field>

                                                                <div class="text-subtitle-1 tw-text-green-900">Localidade</div>
                                                                <v-text-field
                                                                    placeholder="Localidade"
                                                                    v-model="city"
                                                                    required></v-text-field>

                                                                <div class="text-subtitle-1 tw-text-green-900">Quantidade</div>
                                                                <v-text-field
                                                                    placeholder="Quantidade"
                                                                    :hide-spin-buttons="true"
                                                                    type="number"
                                                                    v-model="quantity"
                                                                    class="quantity-input">
                                                                    <template #append>
                                                                        <v-select
                                                                            @click.stop
                                                                            :items="quantityTypesOptions"></v-select>
                                                                    </template>
                                                                </v-text-field>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-text>
                                                </v-card>
                                            </template>

                                            <template v-slot:item.2>
                                                <v-card flat>
                                                    <v-card-text>
                                                        <div class="tw-flex-grid tw-flex">
                                                            <v-radio-group
                                                                v-model="aplicacaoArmazenamento"
                                                                row
                                                                required>
                                                                <label>O produto vai ser: </label>
                                                                <v-radio
                                                                    label="Aplicado"
                                                                    value="aplicado"></v-radio>

                                                                <v-radio
                                                                    label="Armazenamento"
                                                                    value="armazenamento"></v-radio>
                                                            </v-radio-group>

                                                            <v-radio-group
                                                                v-model="sustentavelNaoSustentavel"
                                                                row
                                                                required
                                                                ><label>O produto é:</label>
                                                                <v-radio
                                                                    label="Sustentavel"
                                                                    value="sustentavel"></v-radio>
                                                                <v-radio
                                                                    label="Nao sustentavel"
                                                                    value="naoSustentavel"></v-radio>
                                                            </v-radio-group>
                                                        </div>

                                                        <v-textarea
                                                            row-height="20"
                                                            rows="2"
                                                            label="Processo onde será utilizado (opcional)"
                                                            v-model="processo"
                                                            required></v-textarea>

                                                        <v-textarea
                                                            row-height="20"
                                                            rows="2"
                                                            label="Aplicação do resíduo (opcional)"
                                                            v-model="aplicacaoResiduo"
                                                            required></v-textarea>
                                                    </v-card-text>
                                                </v-card>
                                            </template>
                                        </v-stepper>
                                    </v-container>
                                  
                                </v-card-text>

                                <v-card-actions class="tw-justify-end tw-pb-0 tw-pt-3">
                                    <v-btn
                                        color="black"
                                        variant="flat"
                                        @click="closeDialog"
                                        >Fechar</v-btn
                                    >
                                    <v-btn
                                        color="primary"
                                        variant="flat"
                                        @click="submeterForms"
                                        >{{ $t('views.products.request') }}</v-btn
                                    >
                                </v-card-actions>
                            </v-card>
-->
                          
                        </v-dialog>

                        <v-btn
                            v-if="authStore.isAuthenticated"
                            variant="text"
                            icon
                            color="white"
                            class="tw-h-auto tw-max-h-none tw-w-auto tw-p-2 max-lg:tw-hidden"
                            @click="toggleFavorite">
                            <v-icon
                                class="tw-text-5xl md:tw-text-4xl"
                                :color="isFavorite ? 'secondary' : 'green-900'">
                                {{ isFavorite ? 'mdi-heart' : 'mdi-heart-outline' }}
                            </v-icon>
                        </v-btn>
                    </div>
                </div>
            </div>
            <v-btn
                v-if="authStore.isAuthenticated"
                @click="toggleFavorite"
                variant="text"
                icon
                color="white"
                class="tw-h-auto tw-max-h-none tw-w-auto tw-p-2 max-lg:tw-absolute max-lg:tw-right-0 max-lg:tw-top-0 max-lg:-tw-translate-x-1/3 max-lg:-tw-translate-y-1/2 max-lg:tw-bg-white lg:tw-hidden">
                <v-icon
                    class="tw-text-5xl lg:tw-text-4xl"
                    :color="isFavorite ? 'secondary' : 'green-900'">
                    {{ isFavorite ? 'mdi-heart' : 'mdi-heart-outline' }}
                </v-icon>
            </v-btn>

            <div class="max-lg:tw-absolute max-lg:tw-left-7 max-lg:tw-top-5 max-lg:-tw-translate-x-1/3 max-lg:-tw-translate-y-1/2 lg:tw-hidden">
                <router-link to="/marketplace"> <v-icon>mdi-arrow-left</v-icon> </router-link>
            </div>
        </div>
    </div>

    <div class="tw-pl-[var(--padding-left)] max-lg:tw-max-h-36 max-sm:tw-w-full sm:tw-mx-0 sm:tw-max-w-full"></div>

    <h1 class="tw-pl-[var(--padding-left)] tw-pr-[var(--padding-right)] tw-pt-[var(--padding-top)] tw-text-2xl tw-text-green-900 md:tw-text-3xl 2xl:tw-text-5xl">
        {{ $t('views.products.OtherSuggestions') }}
    </h1>
    <List
        :query="querySuggestions"
        class="tw-pb-24 tw-pl-[var(--padding-left)] tw-pr-[var(--padding-right)]" />
</template>

<script setup lang="ts">
    import List from './List.vue';
    import { ref, onMounted, watch, nextTick } from 'vue';
    import { useRoute } from 'vue-router';
    import axios from '@axios';
    import qs from 'qs';
    import { URL } from 'node:url';
    import { PropType, defineProps } from 'vue';
    import { Product, Media, Vendor } from '../../contentTypes';
    import ioImg from '@/components/ioImg.vue';
    import { computed } from 'vue';
    import { useAuthStore } from '@/store/auth';
    import { useFavorites } from '@/composables/useFavorites';
    //@ts-ignore
    import { Product_QuantityType } from '@/contentTypes.d.ts';
    import { first } from 'lodash';
    import { string } from 'yup';
import { useMedia } from '@/composables/useMedia';

    const dialog = ref(false);

    const closeDialog = () => {
        dialog.value = false;
    };

    const selectedCardIndex = ref<number | null>(null);
    const selectCard = (index: number) => {
        selectedCardIndex.value = index;
    };

    const quantityTypesOptions = Object.values(Product_QuantityType);

    const product = ref<Product>();
    const vendor = ref<Vendor>();
    const authStore = useAuthStore();
    const $favorites = useFavorites();
    const isLoading = ref(true);
    const route = useRoute();

    const address = ref('');
    const zip = ref('');
    const city = ref('');
    const quantity = ref('');
    const aplicacaoArmazenamento = ref('');
    const sustentavelNaoSustentavel = ref('');
    const processo = ref('');
    const aplicacaoResiduo = ref('');

    const vendorId = computed(() => {
        return product.value?.vendor?.id || undefined;
    });

    const showAddAddress = ref(false);

    const toggleAddAddress = () => {
        showAddAddress.value = !showAddAddress.value;
    };

    const submeterForms = async () => {
        const formData = {
            data: {
                address: address.value,
                zip: zip.value,
                city: city.value,
                quantity: quantity.value,
                user: authStore.user?.id,
            },
        };

        try {
            const response = await axios.post('/user-shippings/', formData);
            console.log('Response from server:', response.data);

            const shipping = response.data;
            await submeterOrder(shipping);
            closeDialog();
        } catch (error) {
            console.error('Erro ao enviar formulário:', error);
        }
    };

    const submeterOrder = async (shipping: string) => {
        const orderData = {
            data: {
                user: authStore.user?.id,
                amount: '1',
                product: product.value?.id || '',
                shippingCompany: 4,
                shipping: shipping,
                status: 'pending',
            },
        };

        try {
            const response = await axios.post('/orders/', orderData);
            console.log('Order submitted successfully:', response.data);
        } catch (error) {
            console.error('Error submitting order:', error);
        }
    };

    const isFavorite = computed(() => {
        return !!product.value?.favoriteId;
    });

    const productImages = computed(() => {
        return product.value?.pictures?.map(pic => useMedia(pic).large) || [];
    });

    const querySuggestions = computed(() => ({
        pagination: {
            pageSize: 4,
        },
        filters: {
            id: {
                $ne: route.params.id,
            },
            isFake: {
                $eq: false,
            },
        },
    }));

    watch(
        [() => route.params.id],
        () => {
            console.log('route.params.id:', route.params.id);

            fetchProductDetails();
        },
        {
            flush: 'post',
        },
    );

    /*onMounted(async () => {
        await 
    });*/
    

    console.log(product);

    const fetchProductDetails = async () => {
        try {
            const query = qs.stringify({
                populate: ['category', 'pictures', 'vendor.picture'],
            });
            const response = await axios.get(`/products/${route.params.id}?${query}`);
            product.value = response.data.data;
        } catch (error) {
            console.error('Error fetching product details:', error);
        } finally {
            isLoading.value = false;
        }
    };

    /*
   const firstPicture = computed(() => {
        return (product.value?.pictures?.[0] as Media) || undefined;
    });
   */

    const vendorPicture = computed(() => {
        return (product.value?.vendor?.picture as Media) || undefined;
    });

    const toggleFavorite = async () => {
        try {
            console.log('Toggling favorite for product:', route.params.id);

            if (!isFavorite.value) {
                const { data } = await $favorites.addFavorite('product', route.params.id as string);
                console.log('Favorite added:', data);

                product.value = {
                    ...product.value,
                    favoriteId: data.data.id || null,
                    keywords: product.value?.keywords || '',
                    keywordsSlug: product.value?.keywordsSlug || '',
                };
            } else {
                await $favorites.removeFavorite(product.value?.favoriteId as number);
                console.log('Favorite removed');

                product.value = {
                    ...product.value,
                    favoriteId: undefined || null,
                    keywords: product.value?.keywords || '',
                    keywordsSlug: product.value?.keywordsSlug || '',
                };
            }
        } catch (error) {
            console.error('Erro ao favoritar/desfavoritar o produto:', error);
        }
    };

    const formattedCreatedAt = computed(() => {
        if (product.value?.createdAt) {
            const date = new Date(product.value.createdAt);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        }
        return '';
    });

    const formattedUpdatedAt = computed(() => {
        if (product.value?.updatedAt) {
            const date = new Date(product.value.updatedAt);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        }
        return '';
    });

    //list of countries to autocomplete
    const countries = [
        'Afghanistan',
        'Albania',
        'Algeria',
        'Andorra',
        'Angola',
        'Antigua and Barbuda',
        'Argentina',
        'Armenia',
        'Australia',
        'Austria',
        'Azerbaijan',
        'Bahamas',
        'Bahrain',
        'Bangladesh',
        'Barbados',
        'Belarus',
        'Belgium',
        'Belize',
        'Benin',
        'Bhutan',
        'Bolivia',
        'Bosnia and Herzegovina',
        'Botswana',
        'Brazil',
        'Brunei',
        'Bulgaria',
        'Burkina Faso',
        'Burundi',
        "Côte d'Ivoire",
        'Cabo Verde',
        'Cambodia',
        'Cameroon',
        'Canada',
        'Central African Republic',
        'Chad',
        'Chile',
        'China',
        'Colombia',
        'Comoros',
        'Congo (Congo-Brazzaville)',
        'Costa Rica',
        'Croatia',
        'Cuba',
        'Cyprus',
        'Czechia (Czech Republic)',
        'Democratic Republic of the Congo',
        'Denmark',
        'Djibouti',
        'Dominica',
        'Dominican Republic',
        'Ecuador',
        'Egypt',
        'El Salvador',
        'Equatorial Guinea',
        'Eritrea',
        'Estonia',
        'Eswatini (fmr. "Swaziland")',
        'Ethiopia',
        'Fiji',
        'Finland',
        'France',
        'Gabon',
        'Gambia',
        'Georgia',
        'Germany',
        'Ghana',
        'Greece',
        'Grenada',
        'Guatemala',
        'Guinea',
        'Guinea-Bissau',
        'Guyana',
        'Haiti',
        'Holy See',
        'Honduras',
        'Hungary',
        'Iceland',
        'India',
        'Indonesia',
        'Iran',
        'Iraq',
        'Ireland',
        'Isle of Man',
        'Israel',
        'Italy',
        'Jamaica',
        'Japan',
        'Jersey',
        'Jordan',
        'Kazakhstan',
        'Kenya',
        'Kuwait',
        'Kyrgyz Republic',
        'Laos',
        'Latvia',
        'Lebanon',
        'Lesotho',
        'Liberia',
        'Libya',
        'Liechtenstein',
        'Lithuania',
        'Luxembourg',
        'Macau',
        'Macedonia',
        'Madagascar',
        'Malawi',
        'Malaysia',
        'Maldives',
        'Mali',
        'Malta',
        'Mauritania',
        'Mauritius',
        'Mexico',
        'Moldova',
        'Monaco',
        'Mongolia',
        'Montenegro',
        'Montserrat',
        'Morocco',
        'Mozambique',
        'Namibia',
        'Nepal',
        'Netherlands',
        'Netherlands Antilles',
        'New Caledonia',
        'New Zealand',
        'Nicaragua',
        'Niger',
        'Nigeria',
        'Norway',
        'Oman',
        'Pakistan',
        'Palestine',
        'Panama',
        'Papua New Guinea',
        'Paraguay',
        'Peru',
        'Philippines',
        'Poland',
        'Portugal',
        'Puerto Rico',
        'Qatar',
        'Reunion',
        'Romania',
        'Russia',
        'Rwanda',
        'Saint Pierre &amp; Miquelon',
        'Samoa',
        'San Marino',
        'Satellite',
        'Saudi Arabia',
        'Senegal',
        'Serbia',
        'Seychelles',
        'Sierra Leone',
        'Singapore',
        'Slovakia',
        'Slovenia',
        'South Africa',
        'South Korea',
        'Spain',
        'Sri Lanka',
        'St Kitts &amp; Nevis',
        'St Lucia',
        'St Vincent',
        'St. Lucia',
        'Sudan',
        'Suriname',
        'Swaziland',
        'Sweden',
        'Switzerland',
        'Syria',
        'Taiwan',
        'Tajikistan',
        'Tanzania',
        'Thailand',
        `Timor L'Este`,
        'Togo',
        'Tonga',
        'Trinidad &amp; Tobago',
        'Tunisia',
        'Turkey',
        'Turkmenistan',
        'Turks &amp; Caicos',
        'Uganda',
        'Ukraine',
        'United Arab Emirates',
        'United Kingdom',
        'United States',
        'Uruguay',
        'Uzbekistan',
        'Venezuela',
        'Vietnam',
        'Virgin Islands (US)',
        'Yemen',
        'Zambia',
        'Zimbabwe',
    ];


    fetchProductDetails();
</script>

<style>
    .v-overlay--active {
        backdrop-filter: blur(3px);
        background: rgba(255, 255, 255, 0.8);
    }

    .v-stepper-item--complete .v-stepper-item__avatar.v-avatar,
    .v-stepper-item--selected .v-stepper-item__avatar.v-avatar {
        background-color: rgb(var(--v-theme-primary)) !important;
        color: black !important;
    }
    .custom-dialog .v-overlay__content {
        @apply tw-max-w-[95%] xs:tw-max-w-[90%]  sm:tw-max-w-[85%] md:tw-max-w-[65%];
    }
</style>
