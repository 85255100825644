<template>
    <div class="tw-space-y-4 tw-px-[var(--padding-left)] tw-pb-[var(--padding-bottom)]">
        <div class="tw-flex tw-flex-row tw-gap-6">
            <h1 class="tw-mb-4 tw-text-2xl tw-font-bold">Resumo do Requerimento</h1>
            <!-- Botões de Ação -->
            <div class="tw-mb-6 tw-flex tw-justify-start tw-gap-4">
                <v-btn
                    color="green"
                    icon="mdi-check"
                    @click="aceitar"
                    variant="tonal"></v-btn>
                <v-btn
                    color="red"
                    icon="mdi-close"
                    @click="rejeitar"
                    variant="tonal"></v-btn>
            </div>
        </div>

        <div><strong>Nome:</strong> {{ form.nome }}</div>
        <div><strong>Email:</strong> {{ form.email }}</div>
        <div><strong>Subproduto:</strong> {{ form.subproduto }}</div>
        <div><strong>Utilização Final:</strong> {{ form.utilizacaoFinal }}</div>
        <div><strong>Período de Utilização:</strong> {{ form.periodo }}</div>

        <div><strong>Empresa de Produção:</strong> {{ form.empresaOrigem }}</div>
        <div><strong>Morada da Empresa de Produção:</strong> {{ form.moradaEmpresaOrigem }}</div>
        <div><strong>Código Postal da Empresa de Produção:</strong> {{ form.codigoPostalEmpresaOrigem }}</div>
        <div><strong>Localidade da Empresa de Produção:</strong> {{ form.localidadeEmpresaOrigem }}</div>
        <div><strong>País da Empresa de Produção:</strong> {{ form.paisEmpresaOrigem }}</div>

        <div><strong>Empresa de Utilização:</strong> {{ form.empresaDestino }}</div>
        <div><strong>Morada da Empresa de Utilização:</strong> {{ form.moradaEmpresaDestino }}</div>
        <div><strong>Código Postal da Empresa de Utilização:</strong> {{ form.codigoPostalEmpresaDestino }}</div>
        <div><strong>Localidade da Empresa de Utilização:</strong> {{ form.localidadeEmpresaDestino }}</div>
        <div><strong>País da Empresa de Utilização:</strong> {{ form.paisEmpresaDestino }}</div>

        <div><strong>Empresa Intermédia:</strong> {{ form.empresaIntermedia }}</div>
        <div><strong>Laboratórios:</strong> {{ form.laboratorio.join(', ') }}</div>

        <div><strong>Descrição do Processo Produtivo:</strong> {{ form.descricaoProcesso }}</div>
        <div><strong>Descrição dos Testes:</strong> {{ form.descricaoTestes }}</div>
        <div><strong>Desmantelamento:</strong> {{ form.desmantelamento }}</div>
    </div>
</template>

<script setup lang="ts">
    import { ref } from 'vue';

    const form = ref({
        nome: 'Tintex Textiles, S.A., localizada na Zona Ind. Campos, Pólo 1, 4920-247 V. N. de Cerveira, Portugal',
        email: 'info@tintextextiles.com',
        subproduto: 'Engaço de uva',
        utilizacaoFinal: 'Material têxtil revestido',
        periodo: '6 meses',
        empresaOrigem: 'Quinta de Soalheiro, Lda',
        moradaEmpresaOrigem: 'Lugar da Charneca',
        codigoPostalEmpresaOrigem: '4960-010',
        localidadeEmpresaOrigem: 'Alvaredo',
        paisEmpresaOrigem: 'Portugal',
        empresaDestino: 'Tintex Textiles, S.A.',
        moradaEmpresaDestino: 'Zona Ind. Campos, Pólo 1',
        codigoPostalEmpresaDestino: '4920-247',
        localidadeEmpresaDestino: 'V. N. de Cerveira',
        paisEmpresaDestino: 'Portugal',
        empresaIntermedia: 'O potencial subproduto não será sujeito a processamento adicional numa empresa intermédia antes da utilização final pela Tintex Textiles.',
        laboratorio: ['Laboratório A', 'Laboratório B'],
        descricaoProcesso: 'O potencial subproduto tem a sua origem no processo produtivo de vinificação utilizado pela Quinta de Soalheiro...',
        descricaoTestes: 'O processo de adequação do potencial subproduto será iniciado com o transporte...',
        desmantelamento: 'Não haverá desmantelamento dos equipamentos usados no projeto piloto...',
    });

    const aceitar = () => {
        console.log('Requerimento aceite!');
    };

    const rejeitar = () => {
        console.log('Requerimento rejeitado!');
    };
</script>

<style scoped></style>
