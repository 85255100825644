<template>
    <div class="tw-flex tw-flex-col tw-gap-8 max-lg:tw-px-[var(--padding-left)] max-lg:tw-py-[var(--padding-top)] lg:tw-px-[var(--padding-left)] lg:tw-pb-[var(--padding-bottom)]">
        <template v-if="smAndUp || (!smAndUp && $route.name == 'Profile')">
            <p class="tw-flex tw-h-12 tw-items-center tw-text-green-900 max-lg:tw-justify-center max-lg:tw-text-center max-lg:tw-text-2xl lg:tw-text-left lg:tw-text-5xl xl:tw-mb-4">
                {{ $t('views.profile.perfil') }}
            </p>
            <div class="tw-flex tw-flex-col tw-items-center tw-justify-center sm:tw-hidden">
                <io-img
                    :aspect-ratio="1"
                    cover
                    format="thumbnail"
                    class="tw-w-full tw-max-w-[13.75rem] tw-rounded-4xl"
                    :media="user?.picture"
                    content-type="Media"></io-img>
                <h2 class="tw-mt-4 tw-text-xl">{{ user.username }}</h2>
            </div>
            <div class="profile tw-grid tw-grid-cols-1 tw-gap-8 sm:tw-grid-cols-2 sm:tw-gap-4 lg:tw-grid-cols-5">
                <v-btn
                    class="tw-col-span-1 tw-flex tw-w-full tw-border-2 tw-text-center max-sm:tw-mx-auto max-sm:tw-max-w-xs max-sm:tw-justify-start sm:tw-grow"
                    v-for="route in routes"
                    :active="false"
                    :to="{ name: route.route }"
                    :key="route.route"
                    :text="route.label"
                    :variant="getVariant(route.route)"
                    :size="buttonSize"
                    color="green-900">
                    <template
                        v-if="!smAndUp"
                        #prepend>
                        <v-icon
                            size="32"
                            class="tw-mr-1"
                            >{{ route.icon }}</v-icon
                        >
                    </template>

                    <template
                        v-if="!smAndUp"
                        #append>
                        <img
                            class="tw-ml-auto tw-h-6 tw-w-auto"
                            src="@/assets/icons/chevron-right.svg"
                            alt="" />
                    </template>
                </v-btn>
            </div>
        </template>
        <router-view />
    </div>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import { User } from '@/contentTypes';
    import { ref } from 'vue';
    import { useRoute } from 'vue-router';
    import { useDisplay } from 'vuetify';
    import { useAuthStore } from '@/store/auth';
    import ioImg from '@/components/ioImg.vue';

    const authStore = useAuthStore();

    const user = ref<User>(authStore.getUser);

    const $route = useRoute();
    const { lgAndUp, xlAndUp, smAndUp } = useDisplay();

    //routes are based on user role
    const routes = computed(() => {
        //if user has 'authenticated' role, show only 'ProfileInfo' and 'ProfileFavorites'
        if (user.value.role.name == 'Authenticated') {
            return [
                {
                    label: 'Informações pessoais',
                    route: 'ProfileInfo',
                    icon: 'mdi-account-outline',
                },
                {
                    label: 'Favoritos',
                    route: 'ProfileFavorites',
                    icon: 'mdi-heart-outline',
                },
            ];
            //if user has 'Supplier' role, show only 'ProfileInfo' and 'MyProducts'
        } else if (user.value.role.name == 'Supplier') {
            return [
                {
                    label: 'Informações pessoais',
                    route: 'ProfileInfo',
                    icon: 'mdi-account-outline',
                },
                {
                    label: 'Meus Produtos',
                    route: 'MyProducts',
                },
                {
                    label: 'Formulários',
                    route: 'Forms',
                },
            ];
                
            //else show all routes
        } else {
            return [
                {
                    label: 'Informações pessoais',
                    route: 'ProfileInfo',
                    icon: 'mdi-account-outline',
                },
                {
                    label: 'Favoritos',
                    route: 'ProfileFavorites',
                    icon: 'mdi-heart-outline',
                },
                {
                    label: 'Sugerir',
                    route: 'ProfileSugest',
                },
                {
                    label: 'Meus Produtos',
                    route: 'MyProducts',
                },
            ];
        }
    });

    const currentRoute = computed(() => $route.name);

    const buttonSize = computed(() => {
        if (xlAndUp.value) {
            return 'x-large';
        }

        return 'large';
    });

    function getVariant(route: string) {
        if (currentRoute.value == route && smAndUp.value) {
            return 'flat';
        }

        return 'outlined';
    }
</script>

<style>
    .profile {
        a.v-btn {
            @apply tw-h-auto tw-py-[1em];
        }
        a.v-btn .v-btn__append {
            @apply tw-ml-auto;
        }
    }
</style>
