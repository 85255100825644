<template>
    <div class="tw-flex tw-items-center tw-justify-between tw-gap-4 tw-rounded-2xl tw-bg-pink-600 tw-p-4">
        <v-img
            :aspect-ratio="1"
            contain
            class="tw-w-full tw-max-w-[5.5rem] tw-rounded-xl max-lg:tw-mx-auto 2xl:tw-max-w-[8.75rem]"
            src="https://picsum.photos/600/600">
        </v-img>

        <div class="tw-flex tw-grow tw-flex-col tw-justify-center tw-gap-3">
            <h3 class="tw-text-md tw-font-semibold tw-text-green-900">Req. Espaços Experimentação #44</h3>

            <div class="tw-flex tw-items-center tw-gap-4">
                <v-btn
                    class="tw-rounded-xl tw-bg-primary tw-px-6 tw-font-semibold tw-capitalize tw-text-green-900"
                    size="small"
                    :to="{ name: 'DetalhesPedidosCentrosTecnologicos' }">
                    ver detalhes
                </v-btn>
                <span class="tw-font-medium tw-text-green-900">+ 3 ECO Points</span>
            </div>
        </div>

        <div class="tw-flex tw-flex-col tw-items-center tw-gap-2">
            <span class="tw-text-xl tw-font-bold tw-text-orange-300">Pendente</span>
            <div class="tw-flex tw-gap-2">
                <v-btn
                    class="tw-h-6 tw-w-6 tw-rounded-none tw-bg-green tw-text-white"
                    icon="mdi-check"
                    variant="flat"></v-btn>

                <v-btn
                    class="tw-h-6 tw-w-6 tw-rounded-none tw-bg-red tw-text-white"
                    icon="mdi-close"
                    variant="flat"></v-btn>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { ref, onMounted } from 'vue';
    import axios from 'axios';
    import { useDisplay } from 'vuetify';
    import { useOrder } from '@/composables/useOrder';
    const { xlAndUp } = useDisplay();
    const props = defineProps({
        order: {
            type: Object,
            required: true,
        },
    });
</script>

<style scoped></style>
